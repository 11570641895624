import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Home",
  "includeToc": false,
  "permalink": "/",
  "tags": ["Page", "Home", "Revenue", "Disbursements", "Production"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DataFilterProviderWrapper = makeShortcode("DataFilterProviderWrapper");
const Box = makeShortcode("Box");
const Container = makeShortcode("Container");
const Grid = makeShortcode("Grid");
const Typography = makeShortcode("Typography");
const GlossaryTerm = makeShortcode("GlossaryTerm");
const Hidden = makeShortcode("Hidden");
const WhatsNew = makeShortcode("WhatsNew");
const Tabtastic = makeShortcode("Tabtastic");
const TabtasticTab = makeShortcode("TabtasticTab");
const HomeDataFilters = makeShortcode("HomeDataFilters");
const TotalRevenue = makeShortcode("TotalRevenue");
const ExploreRevenue = makeShortcode("ExploreRevenue");
const TotalDisbursements = makeShortcode("TotalDisbursements");
const ExploreDisbursements = makeShortcode("ExploreDisbursements");
const TotalProduction = makeShortcode("TotalProduction");
const ExploreProduction = makeShortcode("ExploreProduction");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <DataFilterProviderWrapper defaults='home_data_filter_default' mdxType="DataFilterProviderWrapper">
  <Box style={{
        width: "100%",
        padding: "0 0 10px"
      }} mdxType="Box">
  <Container maxWidth="lg" mdxType="Container">
    <Grid container spacing={0} mdxType="Grid">
      <Grid item xs={12} md={7} mdxType="Grid">
        <Typography variant="h2" component="h1" mdxType="Typography">About natural resources revenue data</Typography>
        <Box component="p" pt={0} pb={6} pr={{
                xs: 0,
                md: 3
              }} style={{
                fontWeight: 'normal',
                fontSize: '1.4rem',
                lineHeight: '2.25rem'
              }} mdxType="Box">
            Companies pay to produce energy and minerals on federal lands, Native American lands, and the <GlossaryTerm mdxType="GlossaryTerm">Outer Continental Shelf</GlossaryTerm>.
            The payments these companies make include <GlossaryTerm mdxType="GlossaryTerm">bonuses</GlossaryTerm>, <GlossaryTerm mdxType="GlossaryTerm">rents</GlossaryTerm>, and <GlossaryTerm mdxType="GlossaryTerm">royalties</GlossaryTerm>. <a href="http://www.onrr.gov/">The Office of Natural Resources Revenue (ONRR)</a> collects these payments and distributes them. The payments go to federal and local governments and Native Americans.
        </Box>
      </Grid>
      <Grid item xs={12} md={5} mdxType="Grid">
        <Hidden smDown mdxType="Hidden">
        <Box bgcolor="primary.main" mt={4} mb={4} pt={0.5} pb={2} pl={3} pr={3} borderRadius={10} mdxType="Box">
          <WhatsNew mdxType="WhatsNew" />
        </Box>
        </Hidden>
      </Grid>
    </Grid>
  </Container>
  </Box>
  <Container maxWidth="lg" mdxType="Container">
    <Box mb={2} mdxType="Box">
      <Grid container spacing={0} mdxType="Grid">
        <Grid item xs={12} lg={12} mdxType="Grid">
          <Tabtastic selectedTab={props.location.search} mdxType="Tabtastic">
            <TabtasticTab label="Revenue" mdxType="TabtasticTab">
              <Grid container m={0} spacing={4} mdxType="Grid">
                <Grid item xs={12} mdxType="Grid">
                  <Typography variant="h2" mdxType="Typography">
                    Revenue
                  </Typography>
                  <Typography variant="body1" mdxType="Typography">
                    The amount of money collected from natural resources on property owned by the federal government and Native Americans.
                  </Typography>
                </Grid>
                <Grid item xs={12} mdxType="Grid">
                  <HomeDataFilters mdxType="HomeDataFilters" />
                </Grid>
                <Grid item xs={12} lg={12} mdxType="Grid">
                  <TotalRevenue mdxType="TotalRevenue" />
                </Grid>
                <Grid item xs={12} mdxType="Grid">
                  <ExploreRevenue mdxType="ExploreRevenue" />
                </Grid>
              </Grid>
            </TabtasticTab>
            <TabtasticTab label="Disbursements" mdxType="TabtasticTab">
              <Grid container spacing={4} mdxType="Grid">
                <Grid item xs={12} mdxType="Grid">
                  <Typography variant="h2" mdxType="Typography">
                    Disbursements
                  </Typography>
                  <Typography variant="body1" mdxType="Typography">
                    The amount of money paid to federal and local governments and Native Americans.
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12} mdxType="Grid">
                  <TotalDisbursements mdxType="TotalDisbursements" />
                </Grid>
                <Grid item xs={12} mdxType="Grid">
                  <ExploreDisbursements mdxType="ExploreDisbursements" />
                </Grid>
              </Grid>
            </TabtasticTab>
            <TabtasticTab label="Production" mdxType="TabtasticTab">
              <Grid container spacing={4} mdxType="Grid">
                <Grid item xs={12} mdxType="Grid">
                  <Typography variant="h2" mdxType="Typography">
                    Production
                  </Typography>
                  <Typography variant="body1" mdxType="Typography">
                    The volume of natural resources produced on property owned by the federal government and Native Americans.
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12} mdxType="Grid">
                  <TotalProduction mdxType="TotalProduction" />
                </Grid>
                <Grid item xs={12} mdxType="Grid">
                  <ExploreProduction mdxType="ExploreProduction" />
                </Grid>
              </Grid>
            </TabtasticTab>
            <TabtasticTab label="Fact Sheet" mdxType="TabtasticTab">
              <Grid container spacing={4} mdxType="Grid">
                <Grid item xs={12} mdxType="Grid">
                  <Typography variant="h2" mdxType="Typography">
                  Fact Sheet
                  </Typography>
                  <Typography variant="h3" mdxType="Typography">
                  Monthly Fact Sheet
                  </Typography>
                  <Typography variant="body1" mdxType="Typography">
                    Below is a fact sheet summarizing disbursements, revenue, and production data by month on federal and Native American lands.
                  </Typography>
                  <a href="/fact-sheet/" target="_blank">Most recent monthly fact sheet</a>
                </Grid>
              </Grid>
            </TabtasticTab>
          </Tabtastic>
        </Grid>
      </Grid>
    </Box>
  </Container>
  <Hidden mdUp mdxType="Hidden">
    <Container maxWidth={false} component="section" style={{
          backgroundColor: '#f0f6fa'
        }} mdxType="Container">
      <Container maxWidth="lg" mdxType="Container">
        <Box pt={4} pb={4} mdxType="Box">
          <WhatsNew mdxType="WhatsNew" />
        </Box>
      </Container>
    </Container>
  </Hidden>
    </DataFilterProviderWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      