import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3 {...{
      "id": "Whats-new",
      "style": {
        "position": "relative"
      }
    }}>{`What's new`}</h3>
    <p>{`January 16, 2025 changes:`}</p>
    <ul>
      <li parentName="ul">{`Added `}<Link href="/downloads/disbursements-by-month/" linkType="default" mdxType="Link">{`monthly disbursements data`}</Link></li>
      <li parentName="ul">{`Added `}<Link href="/downloads/revenue/" linkType="default" mdxType="Link">{`monthly revenue data`}</Link></li>
      <li parentName="ul">{`Added `}<Link href="/downloads/production-by-month/" linkType="default" mdxType="Link">{`monthly production data`}</Link>{` `}</li>
      <li parentName="ul">{`Added `}<Link href="/downloads/production-by-disposition/" linkType="default" mdxType="Link">{`monthly production disposition data (OGOR-B)`}</Link></li>
      <li parentName="ul">{`Updated `}<Link href="/fact-sheet" linkType="default" mdxType="Link">{`monthly fact sheet`}</Link></li>
    </ul>
    <p>{`Review our `}<a parentName="p" {...{
        "href": "https://github.com/ONRR/nrrd/releases"
      }}>{`full release details`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      