import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Fact Sheet",
  "includeToc": false,
  "permalink": "/fact-sheet/"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Container = makeShortcode("Container");
const Grid = makeShortcode("Grid");
const FactSheetBanner = makeShortcode("FactSheetBanner");
const ProductionFactSheet = makeShortcode("ProductionFactSheet");
const RevenueFactSheet = makeShortcode("RevenueFactSheet");
const DisbursementsFactSheet = makeShortcode("DisbursementsFactSheet");
const FactSheetFooter = makeShortcode("FactSheetFooter");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Container id="fact-sheet" style={{
      width: '960px'
    }} mdxType="Container">
  <Grid container mdxType="Grid">
    <Grid item xs={12} mdxType="Grid">
      <FactSheetBanner mdxType="FactSheetBanner" />
    </Grid>
    <Grid item xs={12} mdxType="Grid">
      <ProductionFactSheet mdxType="ProductionFactSheet" />
    </Grid>
    <Grid item xs={12} mdxType="Grid">
      <RevenueFactSheet mdxType="RevenueFactSheet" />
    </Grid>
    <Grid item xs={12} mdxType="Grid">
      <DisbursementsFactSheet mdxType="DisbursementsFactSheet" />
    </Grid>
    <FactSheetFooter mdxType="FactSheetFooter" />
  </Grid>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      